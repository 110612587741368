import { render, staticRenderFns } from "./CategoriesEditCreate.vue?vue&type=template&id=b0c469a2"
import script from "./CategoriesEditCreate.vue?vue&type=script&lang=js"
export * from "./CategoriesEditCreate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports