<template>
  <v-row class="fill-height">
    <v-col>
      <v-row>
        <v-col md="12">
          <v-card>
            <v-card-title>
              <v-icon
                @click="pushRouteToView('categories')"
                class="mr-4"
                style="cursor: pointer"
                title="voltar"
              >
                mdi-arrow-left
              </v-icon>
              <v-icon class="mr-2">mdi-format-list-bulleted-square</v-icon>
              {{ method }} categorias
            </v-card-title>
          </v-card>
        </v-col>

        <v-col sm="12">
          <v-form ref="categoryFormValidation" @submit.prevent="setCategory()">
            <v-row justify="center">
              <v-col lg="3" md="4" sm="12">
                <v-text-field
                  outlined
                  v-model="form.name"
                  label="Nome da categoria"
                />
              </v-col>
              <v-col lg="3" md="4" sm="12">
                <v-text-field
                  outlined
                  v-model="form.value"
                  label="Valor da categoria"
                />
              </v-col>
              <v-col sm="10" class="text-right">
                <v-btn
                  type="submit"
                  style="color: #fff; background-color: rgb(61, 17, 89)"
                >
                  <v-icon left>
                    mdi-check
                  </v-icon>
                  Enviar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import store from "@/store";
import {
  CATEGORY_GET,
  CATEGORY_CREATE,
  CATEGORY_EDIT
} from "@/store/actions/categories.type";
import { CATEGORY_RESET_STATE } from "@/store/mutations/categories.type";
import { mapGetters } from "vuex";
export default {
  name: "categoriesEdit",
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    // await store.dispatch(CATEGORY_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    return next();
  },
  data: () => ({
    method: "",
    form: {
      id: 0,
      name: "",
      value: ""
    }
  }),

  async mounted() {
    // get route params
    const { id } = this.$route.params;
    this.form.id = id;
    store.commit(CATEGORY_RESET_STATE);
    this.method = await this.checkRoute();
    this[this.method]();
  },
  computed: {
    ...mapGetters(["category"])
  },

  methods: {
    pushRouteToView(route) {
      this.$router.push({ name: route });
    },
    async checkRoute() {
      return new Promise(resolve => {
        //alert(this.$router.currentRoute.name)
        if (this.$router.currentRoute.name == "categoriesAdd") {
          //alert("a")
          resolve("Adicionar");
        } else {
          //alert("a")
          resolve("Editar");
        }
      });
    },
    Adicionar() {
      console.log("adicionar modo");
    },
    Criar() {},
    async Editar() {
      await Promise.all([store.dispatch(CATEGORY_GET, this.form.id)]);

      this.form.name = this.category.name;
      this.form.value = this.category.value;
      this.form.id = this.category.id;
    },
    setCategory() {
      if (this.method == "Adicionar") {
        store.dispatch(CATEGORY_CREATE, this.form);
      }
      if (this.method == "Editar") {
        store.dispatch(CATEGORY_EDIT, this.form);
      }
    },
    categoryFormValidation() {
      return this.$refs.categoryFormValidation.validate();
    }
  }
};
</script>
